export const cufrontLogos = {
    topbar: {
        cufront:
            "https://cufront-assets.s3.amazonaws.com/logos/cufront+logomark+.svg",
        ndhealthwellclinic:
            "https://cufront-assets.s3.amazonaws.com/logos/cufront+logo.svg",
        omkarhospitals:
            "https://cufrontdata.s3.us-east-1.amazonaws.com/clinics/11/38-01.jpg",
    },
    navbar: {
        cufront:
            "https://cufront-assets.s3.amazonaws.com/logos/cufront+logo.svg",
        ndhealthwellclinic:
            "https://cufront-assets.s3.amazonaws.com/logos/cufront+logo.svg",
        omkarhospitals:
            "https://cufrontdata.s3.us-east-1.amazonaws.com/clinics/11/38-01.jpg",
    },
    ndhealthwellclinic:
        "https://cufront-assets.s3.amazonaws.com/logos/healthwellLogo.png",

    omkarhospitals:
        "https://cufrontdata.s3.us-east-1.amazonaws.com/clinics/11/38-01.jpg",
    cufront: "https://cufront-assets.s3.amazonaws.com/logos/cufront+logo.svg",
};
