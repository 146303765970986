import React, { useState } from "react";
import { FaUser, FaChevronDown, FaExclamationCircle } from "react-icons/fa";
import { Questionnaires } from "../utils/questionUtils";

const FormView = () => {
    const [formData, setFormData] = useState({});
    const [visibleQuestions, setVisibleQuestions] = useState(1); // Tracks how many questions are visible

    const handleChange = (categoryId, questionId, value) => {
        setFormData((prev) => ({
            ...prev,
            [categoryId]: {
                ...prev[categoryId],
                [questionId]: value,
            },
        }));
    };

    const shouldRenderDependentQuestions = (
        dependentQuestions,
        parentAnswer
    ) => {
        if (!dependentQuestions) return false;

        if (
            typeof dependentQuestions === "object" &&
            !Array.isArray(dependentQuestions)
        ) {
            return !!dependentQuestions[parentAnswer];
        }

        return true;
    };

    const renderDependentQuestions = (
        dependentQuestions,
        categoryId,
        parentAnswer
    ) => {
        if (!shouldRenderDependentQuestions(dependentQuestions, parentAnswer))
            return null;

        if (Array.isArray(dependentQuestions)) {
            return dependentQuestions.map((question) =>
                renderQuestion(categoryId, question)
            );
        }

        return dependentQuestions[parentAnswer]?.map((question) =>
            renderQuestion(categoryId, question)
        );
    };

    const renderQuestion = (categoryId, question) => {
        const {
            question_id,
            text,
            type,
            options,
            is_required,
            dependent_questions,
        } = question;

        switch (type) {
            case "text":
            case "number":
                return (
                    <div key={question_id} className="mb-6">
                        <label className="block font-medium mb-1 text-gray-700 flex items-center">
                            <FaUser className="w-5 h-5 mr-2 text-gray-500" />
                            {text}
                            {is_required && (
                                <FaExclamationCircle className="w-4 h-4 ml-1 text-red-500" />
                            )}
                        </label>
                        <input
                            type={type}
                            placeholder={`Enter your ${text.toLowerCase()}`}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            required={is_required}
                            onChange={(e) =>
                                handleChange(
                                    categoryId,
                                    question_id,
                                    e.target.value
                                )
                            }
                        />
                        {renderDependentQuestions(
                            dependent_questions,
                            categoryId,
                            formData[categoryId]?.[question_id]
                        )}
                    </div>
                );
            case "dropdown":
                return (
                    <div key={question_id} className="mb-6">
                        <label className="block font-medium mb-1 text-gray-700 flex items-center">
                            <FaChevronDown className="w-5 h-5 mr-2 text-gray-500" />
                            {text}
                            {is_required && (
                                <FaExclamationCircle className="w-4 h-4 ml-1 text-red-500" />
                            )}
                        </label>
                        <select
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            required={is_required}
                            onChange={(e) =>
                                handleChange(
                                    categoryId,
                                    question_id,
                                    e.target.value
                                )
                            }
                        >
                            <option value="">Select</option>
                            {options.map((option, index) => (
                                <option key={index} value={option.label}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {renderDependentQuestions(
                            dependent_questions,
                            categoryId,
                            formData[categoryId]?.[question_id]
                        )}
                    </div>
                );
            case "checkbox_group":
                return (
                    <div key={question_id} className="mb-6">
                        <label className="block font-medium mb-2 text-gray-700">
                            {text}
                            {is_required && (
                                <FaExclamationCircle className="w-4 h-4 ml-1 text-red-500 inline" />
                            )}
                        </label>
                        <div className="space-y-2">
                            {options.map((option, index) => (
                                <div key={index} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id={`${question_id}_${index}`}
                                        value={option.label}
                                        className="mr-2"
                                        onChange={(e) => {
                                            const selectedOptions =
                                                formData[categoryId]?.[
                                                    question_id
                                                ] || [];
                                            const updatedOptions = e.target
                                                .checked
                                                ? [
                                                      ...selectedOptions,
                                                      e.target.value,
                                                  ]
                                                : selectedOptions.filter(
                                                      (val) =>
                                                          val !== e.target.value
                                                  );
                                            handleChange(
                                                categoryId,
                                                question_id,
                                                updatedOptions
                                            );
                                        }}
                                    />
                                    <label
                                        htmlFor={`${question_id}_${index}`}
                                        className="text-gray-700"
                                    >
                                        {option.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                        {options.map((option) =>
                            formData[categoryId]?.[question_id]?.includes(
                                option.label
                            )
                                ? renderDependentQuestions(
                                      option.dependent_questions,
                                      categoryId,
                                      option.label
                                  )
                                : null
                        )}
                    </div>
                );
            case "yes_no":
                return (
                    <div key={question_id} className="mb-6">
                        <label className="block font-medium mb-1 text-gray-700 flex items-center">
                            {text}
                            {is_required && (
                                <FaExclamationCircle className="w-4 h-4 ml-1 text-red-500" />
                            )}
                        </label>
                        <div className="flex space-x-4">
                            {["yes", "no"].map((value) => (
                                <label
                                    key={value}
                                    className="flex items-center"
                                >
                                    <input
                                        type="radio"
                                        name={question_id}
                                        value={value}
                                        className="mr-2"
                                        onChange={(e) =>
                                            handleChange(
                                                categoryId,
                                                question_id,
                                                e.target.value
                                            )
                                        }
                                    />
                                    {value.charAt(0).toUpperCase() +
                                        value.slice(1)}
                                </label>
                            ))}
                        </div>
                        {renderDependentQuestions(
                            dependent_questions?.[
                                formData[categoryId]?.[question_id]
                            ],
                            categoryId,
                            formData[categoryId]?.[question_id]
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    const handleNext = () => {
        setVisibleQuestions((prev) => prev + 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data:", formData);
        alert("Form submitted successfully!");
    };

    const allQuestions = Questionnaires[0].categories.flatMap((category) =>
        category.questions.map((question) => ({
            ...question,
            categoryId: category.category_id,
        }))
    );

    return (
        <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold mb-8 text-center text-blue-600">
                {Questionnaires[0].template_name}
            </h1>
            <form onSubmit={handleSubmit}>
                {allQuestions
                    .slice(0, visibleQuestions)
                    .map((question) =>
                        renderQuestion(question.categoryId, question)
                    )}
                <div className="flex justify-end mt-4">
                    {visibleQuestions < allQuestions.length ? (
                        <button
                            type="button"
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    ) : (
                        <button
                            type="submit"
                            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                        >
                            Submit
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default FormView;
