export const Questionnaires = [
    {
        template_id: "1",
        template_name: "General Health Check",
        categories: [
            {
                category_id: "101",
                category_name: "Personal Information",
                questions: [
                    {
                        question_id: "1001",
                        text: "What is your full name?",
                        type: "text",
                        is_required: true,
                    },
                    {
                        question_id: "1002",
                        text: "What is your age?",
                        type: "number",
                        is_required: true,
                    },
                    {
                        question_id: "1003",
                        text: "What is your gender?",
                        type: "dropdown",
                        options: [
                            { label: "Male" },
                            { label: "Female" },
                            { label: "Other" },
                        ],
                        is_required: true,
                    },
                ],
            },
            {
                category_id: "102",
                category_name: "Medical History",
                questions: [
                    {
                        question_id: "2001",
                        text: "Do you have any pre-existing conditions?",
                        type: "checkbox_group",
                        options: [
                            { label: "Diabetes", dependent_questions: [] },
                            {
                                label: "Hypertension",
                                dependent_questions: [
                                    {
                                        question_id: "3001",
                                        text: "How long have you had hypertension?",
                                        type: "text",
                                        is_required: true,
                                    },
                                    {
                                        question_id: "3002",
                                        text: "Are you on medication for hypertension?",
                                        type: "yes_no",
                                        is_required: true,
                                        dependent_questions: {
                                            yes: [
                                                {
                                                    question_id: "3003",
                                                    text: "What medication are you taking?",
                                                    type: "text",
                                                    is_required: true,
                                                },
                                            ],
                                            no: [
                                                {
                                                    question_id: "3004",
                                                    text: "Have you consulted a doctor for hypertension?",
                                                    type: "yes_no",
                                                    is_required: false,
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                            { label: "Heart Disease", dependent_questions: [] },
                            { label: "None", dependent_questions: [] },
                        ],
                        is_required: true,
                    },
                    {
                        question_id: "2002",
                        text: "Are you currently taking any medications?",
                        type: "text",
                        is_required: false,
                    },
                ],
            },
        ],
    },
];
