import { useAtom } from "jotai";
import "../assets/css/Navbar.css";
import { cufrontLogos } from "../assets/logos/logos";
import { useNavbarOptions } from "../constants/constant";
import CuAvatar from "../components/CuAvatar";
import { selectedUserAtom, userDataAtom } from "../config/globalStates";
import { useEffect, useRef, useState } from "react";
import { NAVBAR_ICONS } from "../assets/icons/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { getImageURL } from "../apis/image";
import { Logout } from "../apis/authSetup";
import { useTranslation } from "react-i18next";

const Navbar = ({ subdomain }) => {
    const { t } = useTranslation();
    const navbarOptions = useNavbarOptions();
    const [userData] = useAtom(userDataAtom);
    const [selectedUser, setSelectedUser] = useAtom(selectedUserAtom);
    const [isViewOptions, setIsViewOptions] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const optionsRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                optionsRef.current &&
                !optionsRef.current.contains(event.target)
            ) {
                setIsViewOptions(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionsRef]);

    const handleViewOptions = () => {
        setIsViewOptions((prev) => !prev);
    };

    const handleSelectOption = (i) => {
        setSelectedUser(userData[i]);
        setIsViewOptions(false);
    };

    const handleLogOut = () => {
        Logout();
        if (subdomain !== "cufront") {
            // window.location.href = "https://ndhealthwellclinic.com/";
            navigate("/login");
        } else {
            const hostname = window.location.hostname; // Get full domain (e.g., app.xyz.com)
            const parts = hostname.split("."); // Split by dots

            // Check if there are at least 3 parts (subdomain + main domain)
            if (parts.length > 2) {
                const mainDomain = parts.slice(-2).join("."); // Extract last two parts (e.g., xyz.com)
                window.location.href = `https://www.${mainDomain}`; // Redirect to main domain
            }
        }
    };

    const handleHomeNavigate = () => {
        if (subdomain !== "cufront") {
            const hostname = window.location.hostname; // Get full domain (e.g., app.xyz.com)
            const parts = hostname.split("."); // Split by dots

            // Check if there are at least 3 parts (subdomain + main domain)
            if (parts.length > 2) {
                const mainDomain = parts.slice(-2).join("."); // Extract last two parts (e.g., xyz.com)
                window.location.href = `https://www.${mainDomain}`; // Redirect to main domain
            }
        } else {
            navigate("/p");
        }
    };

    return (
        <div className="cu-navbar-main">
            <div
                onClick={handleHomeNavigate}
                className=" large:w-[150px] desktop:w-[140px] laptop:w-[130px] w-[130px] cursor-pointer"
            >
                <img
                    className="h-12 object-contain"
                    src={cufrontLogos[subdomain]}
                    alt="logo"
                />
            </div>
            <div className="cu-navbar-link text-sm laptop:text-sm desktop:text-sm large:text-lg xlarge:text-xl">
                {navbarOptions.map((navlink) => (
                    <span
                        className={
                            location.pathname === navlink.route
                                ? "cu-navbar-link-active flex items-center gap-2"
                                : "flex items-center gap-2"
                        }
                        key={navlink.id}
                        onClick={() => navigate(navlink.route)}
                    >
                        {location.pathname === navlink.route
                            ? navlink.activeIcon
                            : navlink.inactiveIcon}

                        {navlink.name}
                    </span>
                ))}
            </div>
            <div
                className="cu-navbar-profile cursor-pointer"
                onClick={handleViewOptions}
            >
                <div>
                    {selectedUser?.profile_image ? (
                        <img
                            className="w-full h-full rounded-full"
                            src={getImageURL(selectedUser?.profile_image)}
                            alt="..."
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <div className="cu-nav-profile-username text-sm">
                    <span>{`${selectedUser?.full_name}`}</span>
                    <span>{selectedUser?.phone}</span>
                </div>
            </div>
            {isViewOptions && (
                <div className="navbar-family-options h-fit" ref={optionsRef}>
                    <div className="h-[250px] overflow-scroll">
                        {userData?.map((user, i) => (
                            <div
                                className="navbar-family-option"
                                key={user?.id}
                                onClick={() => handleSelectOption(i)}
                            >
                                {user?.profile_image ? (
                                    <>
                                        <img
                                            className="w-[37px] h-[37px] rounded-full"
                                            src={getImageURL(
                                                user?.profile_image
                                            )}
                                            alt="..."
                                        />
                                    </>
                                ) : (
                                    <CuAvatar name={user?.full_name[0]} />
                                )}
                                <span>{user?.full_name}</span>
                            </div>
                        ))}
                    </div>
                    <div onClick={handleLogOut} className="navbar-view-profile">
                        <img src={NAVBAR_ICONS.profile} alt="..." />
                        <span>{t("logout")}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
